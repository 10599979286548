html, body {
  height: 100%;
}
body {
  margin: 0;
}

.flex-col-center{
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body table tbody {
  width: 100%;
}

.statLabel{
  padding:4px 8px;
  background-color: #EDEDED;
  margin: 4px 8px;
  display:inline-block;
  border-radius: 4px;
}

.mainLogo{
  margin-bottom: 27px;
}
.mainLogo span{
  font-size: 23px;
  color: #dd4b39;
}

.mainLogo img{
  margin-bottom: -9px;
}

.body{
  display: flex;
  flex-direction: column;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.appHeader{
  padding: 10px 20px !important;
  margin: 0 !important;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(231, 231, 231);
}

.appHeader>div{
  padding: 0 !important;
  margin: 0 !important;
}

.gridRowStyle{
  border-width: 1px;
  border-style: solid;
  border-color: rgb(231, 231, 231);
}

.dropdownWidth{
  min-width: 100px !important;
  max-width: 130px !important;
}

.userName{
  margin: 0 14px 0 0px;
  font-size: 12px;
}
.userAvatar{
  width: 25px !important;
  height: 25px !important;
}
.full {
  width: 100%;
}

.react-datepicker-wrapper input{
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  padding: .67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  transition: box-shadow .1s ease,border-color .1s ease;
  box-shadow: none;
}

.spaceBetween{
  display: flex !important;
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(231, 231, 231);
  padding: 5px 0 !important;
}

.spaceBetween:last-child{
  border:none;
}

.spaceBetween>span{
  width: 75%;
  margin-right: 5%;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.reportHolidayRow{
  background-color: #c8e7ff;
}

.reportWeekendRow {
  background-color: #f1f1f1;
  padding: 0;
}

.reportWeekendRow div{
  padding: 4px 14px !important;
}